<template>
  <div id="quickModule">
    <div class="card-left">
      <div class="title flex-align">
        <div>
          <span>{{ $t('pages_main_051') }}</span>
          <span>{{ $t('pages_main_052') }}</span>
        </div>
        <div class="date-select">
          <a-button
            size="small"
            style="font-size: 12px"
            :type="searchQuery.scope === 'WEEK' ? 'link' : 'text'"
            @click="dateClick('WEEK')"
          >{{ $t('pages_main_048') }}</a-button>
          <a-button
            size="small"
            style="font-size: 12px"
            :type="searchQuery.scope === 'MONTH' ? 'link' : 'text'"
            @click="dateClick('MONTH')"
          >{{ $t('pages_main_049') }}</a-button>
          <a-button
            size="small"
            style="font-size: 12px"
            :type="searchQuery.scope === 'YEAR' ? 'link' : 'text'"
            @click="dateClick('YEAR')"
          >{{ $t('pages_main_050') }}</a-button>
        </div>
      </div>
      <div class="left-list">
        <div class="module-info">
          <div class="name">{{ $t('pages_router_003') }}</div>
          <a-skeleton v-if="tradeLoading" active :paragraph="false" />
          <a-tooltip v-else :title="tradeInfo.receiveAmt">
            <div class="amount">{{ tradeInfo.receiveAmt }}</div>
          </a-tooltip>
        </div>
        <div class="module-info">
          <div class="name">{{ $t('pages_router_016') }}</div>
          <a-skeleton v-if="tradeLoading" active :paragraph="false" />
          <a-tooltip v-else :title="tradeInfo.exchangeAmt">
            <div class="amount">{{ tradeInfo.exchangeAmt }}</div>
          </a-tooltip>
        </div>
        <div class="module-info">
          <div class="name">{{ $t('pages_router_007') }}</div>
          <a-skeleton v-if="tradeLoading" active :paragraph="false" />
          <a-tooltip v-else :title="tradeInfo.payAmt">
            <div class="amount">{{ tradeInfo.payAmt }}</div>
          </a-tooltip>
        </div>
      </div>
    </div>
    <div class="card-right">
      <div class="title">{{ $t('pages_main_012') }}</div>
      <div class="right-list">
        <div class="module-info" @click="$router.push('/receive/manage')">
          <div class="name">{{ $t('pages_main_014') }}</div>
          <a-skeleton v-if="waitLoading" active :paragraph="false" />
          <div v-else class="amount">{{ waitInfo.receive }}</div>
        </div>
        <div class="module-info" @click="$router.push({ path: '/funds/review', query: { key: 'outbound' } })">
          <div class="name">{{ $t('pages_main_021') }}</div>
          <a-skeleton v-if="waitLoading" active :paragraph="false" />
          <div v-else class="amount">{{ waitInfo.outPay }}</div>
        </div>
        <div class="module-info" @click="$router.push({ path: '/funds/review', query: { key: 'inbound' } })">
          <div class="name">{{ $t('pages_main_020') }}</div>
          <a-skeleton v-if="waitLoading" active :paragraph="false" />
          <div v-else class="amount">{{ waitInfo.pay }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { _home } from '@/api/index'
import { parseMoney } from '@/util'
export default {
  name: 'QuickModule',
  setup() {
    const state = reactive({
      waitLoading: true,
      tradeLoading: true,
      waitInfo: {},
      tradeInfo: {},
      searchQuery: {
        scope: 'YEAR'
      }
    })

    const dateClick = (scope) => {
      state.searchQuery.scope = scope
      loadTradeRatio()
    }

    const loadTradeRatio = async () => {
      state.tradeLoading = true
      const res = await _home.getTradeRatio(state.searchQuery)
      if (res.data.succ) {
        const { data } = res.data
        state.tradeInfo = Object.assign({}, res.data.data, {
          receiveAmt: data.receiveAmount ? `$${parseMoney(data.receiveAmount)}` : 0,
          exchangeAmt: data.exchangeAmount ? `$${parseMoney(data.exchangeAmount)}` : 0,
          payAmt: data.payAmount ? `$${parseMoney(data.payAmount)}` : 0
        })
      }
      state.tradeLoading = false
    }

    const loadBusStatistics = async () => {
      state.waitLoading = true
      const res = await _home.getBusStatistics()
      if (res.data.succ) {
        state.waitInfo = res.data.data
      }
      state.waitLoading = false
    }

    const init = () => {
      loadTradeRatio()
      loadBusStatistics()
    }

    init()
    return {
      dateClick,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#quickModule {
  margin-top: 18px;
  display: flex;
  .card-left, .card-right {
    flex: 0 0 49%;
    padding: 18px 24px 18px 32px;
    border-radius: 8px;
    background: #fff;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      span:nth-child(2) {
        margin-left: 5px;
        font-size: 14px;
        color: #666;
      }
      .date-select {
        margin-left: auto;
      }
    }
    .left-list, .right-list {
      display: flex;
      padding: 18px 12px;
      margin-top: 18px;
      flex-wrap: wrap;
      height: 100px;
      border: 1px solid transparent;
      border-radius: 8px;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, #fff, #fff), linear-gradient(230deg, #4065e0, #de3c1a);
      .module-info {
        flex: 0 0 33.33%;
        border-right: 1px solid #ECEFFC;
        padding: 0 12px;
        overflow: hidden;
        cursor: pointer;
        .ellipsis {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .name {
          .ellipsis;
          color: @text-color;
          font-weight: 500;
        }
        .amount {
          .ellipsis;
          margin-top: 8px;
          font-size: 20px;
          font-weight: 500;
          color: #333;
        }
      }
      .module-info:last-child {
        border-right: 0;
      }
    }
  }
  .card-right {
    margin-left: 2%;
  }
}
</style>
