<template>
  <div id="areaEcharts">
    <div class="search-tab">
      <a-radio-group v-model:value="searchQuery.type" @change="loadAmountRatio">
        <a-radio-button value="AMOUNT">{{ $t('pages_main_046') }}</a-radio-button>
        <a-radio-button value="PENCOUNT">{{ $t('pages_main_047') }}</a-radio-button>
      </a-radio-group>
      <div class="date-select">
        <a-button
          :type="searchQuery.scope === 'WEEK' ? 'link' : 'text'"
          @click="dateClick('WEEK')"
        >{{ $t('pages_main_048') }}</a-button>
        <a-button
          :type="searchQuery.scope === 'MONTH' ? 'link' : 'text'"
          @click="dateClick('MONTH')"
        >{{ $t('pages_main_049') }}</a-button>
        <a-button
          :type="searchQuery.scope === 'YEAR' ? 'link' : 'text'"
          @click="dateClick('YEAR')"
        >{{ $t('pages_main_050') }}</a-button>
      </div>
    </div>
    <div class="charts-area">
      <div class="charts-title">{{ $t('pages_main_043') }}</div>
      <div class="pie-title">{{ $t('pages_main_044') }}</div>
      <div v-show="chartsLoading && !isLine" id="lineLoading" class="flex-align">
        <a-spin />
      </div>
      <div v-show="!chartsLoading && isLine" id="lineCharts" />
      <div v-show="!chartsLoading && !isLine" id="lineEmpty" class="flex-align" style="justify-content: center">
        <a-empty :image="simpleImage" />
      </div>

      <div v-show="chartsLoading && !isPie" id="pieLoading" class="flex-align">
        <a-spin />
      </div>
      <div v-show="!chartsLoading && isPie" id="pieCharts" />
      <div v-show="!chartsLoading && !isLine" id="pieEmpty" class="flex-align" style="justify-content: center">
        <a-empty :image="simpleImage" />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, computed, nextTick } from 'vue'
import { _home } from '@/api'
import { Empty } from 'ant-design-vue'
import { parseMoney } from '@/util'
import i18n from '@/locale'
import * as echarts from 'echarts'
export default {
  name: 'AreaEcharts',
  setup() {
    const state = reactive({
      searchQuery: {
        startDate: '',
        endDate: '',
        scope: 'YEAR',
        type: 'AMOUNT'
      },
      chartsLoading: true,
      isLine: false,
      isPie: false
    })

    state.simpleImage = computed(() => {
      return Empty.PRESENTED_IMAGE_SIMPLE
    })

    onMounted(() => loadAmountRatio())

    const renderLineCharts = (dataX, dataY) => {
      const myChart = echarts.init(document.getElementById('lineCharts'))
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          }
        },
        xAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          type: 'category',
          data: dataX
        },
        yAxis: {
          type: 'value',
          splitLine: false,
          axisLabel: {
            formatter: function (value, index) {
              if (value / 10000000 > 1) return `${(value / 10000000).toFixed(0)}kw`
              if (value / 10000 > 1) return `${(value / 10000).toFixed(0)}w`
              return value.toFixed(0)
            }
          }
        },
        grid: {
          show: false,
          left: '7%',
          right: '7%',
          bottom: '7%'
        },
        series: [{
          name: i18n.global.t('pages_main_043'),
          type: 'bar',
          data: dataY,
          barWidth: 15,
          itemStyle: {
            borderRadius: [8, 8, 8, 8],
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#668AFF' },
              { offset: 0.5, color: '#4C70E8' },
              { offset: 1, color: '#4065E0' }
            ])
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#4065E0' },
                { offset: 0.7, color: '#4C70E8' },
                { offset: 1, color: '#668AFF' }
              ])
            }
          }
        }]
      }
      myChart.setOption(option)
    }

    const renderPieCharts = (data, sectorAllAmt) => {
      const myChart = echarts.init(document.getElementById('pieCharts'))
      const option = {
        title: {
          text: i18n.global.t('pages_main_045'),
          textStyle: {
            fontSize: 14,
            color: '#333'
          },
          left: '29.5%',
          top: '47%',
          subtext: `$${sectorAllAmt}`,
          subtextStyle: {
            fontSize: 18,
            fontWeight: 'bold',
            color: '#000'
          },
          textAlign: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return i18n.global.t('pages_main_045') + '<br />' + params.data.name + ':' +
              `<span style="margin-left: 12px; font-weight: 600">${params.data.sourceValue}</span>`
          }
        },
        legend: {
          right: 0,
          top: '20%',
          orient: 'vertical',
          y: 'center',
          itemGap: 28,
          itemWidth: 7,
          itemHeight: 7,
          icon: 'circle',
          itemStyle: {
            borderWidth: 0
          },
          formatter: function(name) {
            let total = 0
            let target
            for (let i = 0; i < data.length; i++) {
              total += data[i].value
              if (data[i].name === name) {
                target = data[i].value
              }
            }
            var arr = [
              `{a|${name}}`,
              `{c|${((target / total) * 100).toFixed(2)}%}`
            ]
            return arr.join('')
          },
          textStyle: {
            rich: {
              c: { color: '#c1c1c1', padding: [0, 0, 0, 12] }
            }
          }
        },
        color: ['#DE3C1A', '#6FCAFA', '#1EDBE2', '#17CDB4', '#FCBCB2', '#4065E0'],
        series: [
          {
            name: i18n.global.t('pages_main_045'),
            type: 'pie',
            radius: ['50%', '67%'],
            center: ['31%', '57%'],
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 5
            },
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data
          }
        ]
      }
      myChart.setOption(option)
    }

    const dateClick = (scope) => {
      state.searchQuery.scope = scope
      loadAmountRatio()
    }

    const loadAmountRatio = async () => {
      state.chartsLoading = true
      state.isLine = false
      state.isPie = false
      const res = await _home.getAmountRatio(state.searchQuery)
      if (res.data.succ) {
        const { xaxis, yaxis, sectorData, sectorAllAmt } = res.data.data
        state.isLine = xaxis && yaxis
        state.isPie = yaxis
        nextTick(() => {
          renderLineCharts(xaxis || [], yaxis || [])
          renderPieCharts(sectorData || [], parseMoney(sectorAllAmt))
        })
      }
      state.chartsLoading = false
    }

    return {
      dateClick,
      loadAmountRatio,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#areaEcharts {
  background: #fff;
  margin-top: 18px;
  border-radius: 8px;
  padding: 24px;
  .search-tab {
    display: flex;
    .date-select {
      margin-left: auto;
    }
  }
  .charts-area {
    display: flex;
    margin-top: 18px;
    position: relative;
    .charts-title, .pie-title {
      position: absolute;
      left: 0;
      font-size: 18px;
      font-weight: 600;
    }
    .pie-title {
      right: -258px;
    }
    #lineCharts, #pieCharts, #lineEmpty, #pieEmpty, #lineLoading, #pieLoading {
      flex: 1;
      height: 300px;
      justify-content: center;
    }
    #lineCharts, #lineEmpty, #lineLoading {
      flex: 1.5;
    }
  }
}
</style>

